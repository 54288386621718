<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-primary">
                <div class="footer-logo">
                    <a :href="url_prefix+'/'">
                        <img src="@/assets/img/Logo.png" alt="#" class="">
                    </a>
                </div>
                <nav>
                    <ul>
                        <li>
                            <a :href="url_prefix+'/politica-de-confidentialitate'" target="_blank">Politica de confidențialitate</a>
                        </li>
                    </ul>
                </nav>
                <div class="contact-information">
                    <br>
                    <p class="adres">Chișinău, Moldova, str. Pușkin 22</p>
                    <p class="adres">Chișinău, Moldova, bd. Traian 15</p>
                </div>
            </div>
            <div class="footer-secondary">
                <nav>
                    <ul>
                        <li>
                            <a :href="url_prefix+'/politica-de-confidentialitate'" target="_blank">Politica de confidențialitate</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </footer>
</template>

<style lang="scss">
    footer{
        >.container{
            .footer-primary{
                width:100%;
                .contact-information{
                    .adres{

                    }
                }
            }
            .footer-secondary{
                display: none;
                width: 100%;
            }
        }
    }
    @media(max-width:900px){
        footer{
            >.container{
                .footer-primary{
                    nav{
                        display:none;
                    }
                    .contact-information{
                        margin:2rem 0px 30px;
                        .adres{
                            text-align: left;
                        }
                    }
                }
                .footer-secondary{
                    display: inline-block;
                    
                }
            }
        }
    }
</style>

<script>
    import i18n from '@/i18n';
    export default {
        name: 'Footer',
        components: {},
        data: function () {
            return {
                login_container_visible:false
            }
        },
        methods: {},
        computed:{
            url_prefix(){
                return (i18n.locale=="ro"?"":"/"+i18n.locale);
            }
        },
    }
</script>