<template>
    <div>
        <section class="main">
            <div class="container">
                <div class="title row">
                    <!--<h1 class="Futura">Lecții individuale la <span> șah online</span></h1>-->
                    <h1 class="Futura">Cursuri de șah online și pe viu</h1>
                </div>
                <div class="main-elements">
                    <div class="row justify-content-between" style="width: 100%;">
                        <RegistrationForm />
                        <!--
                        <div class="copii col-xl-6 col-lg-6 col-sm-6 col-sm-12">
                            <img src="@/assets/img/copii.png" alt="#">
                        </div>
                        -->
                        <div class="copii col-xl-6 col-lg-6 col-sm-6 col-sm-12">
                            <div class="hero-wrapper">
                                <div class="hero-block">
                                    <div class="hero-caption" v-html="hero.caption"></div>
                                    <p v-html="hero.description"></p>
                                </div>
                                <div class="g g-top">
                                    <div class="c3">

                                    </div>
                                </div>
                                <div class="g g-bottom">
                                    <div class="c1">

                                    </div>
                                    <div class="c2">

                                    </div>
                                </div>
                                <div class="cloud-wrapper">
                                    <img src="@/assets/img/Cloud.png" alt="#" class="cloud">
                                    <span class="cloud-text" v-html="hero.cloud"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="first-section">
            <div class="container">
                <div class="row section_title">
                    <p>Vom învăța să luăm decizii echilibrate și<br><span>îmbunătăți performanța la studii</span></p>
                </div>
                <div class="row">
                <div class="archery">
                    <img src="@/assets/img/archery1.svg" alt="">
                </div>
                <div class="video">
                    <video id="vid" style="height:100%;" playsinline autoplay muted loop>
                        <source src="@/assets/videos/VideoCompressed.mp4" type="video/mp4">
                    </video>
                </div>
                </div>
            </div>
        </section>
        <section class="section-two" style="">
            <div class="container">
                <div class="row section_title">
                    <p>Program de studii individual<br><span>luând în calcul nivelul și scopurile</span></p>
                </div>
                <div class="row">
                    <p class="text_s">Vom învăța să luăm decizii echilibrate și<br> îmbunătăți performanța la studii</p>
                </div>
                <div class="row blocks justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="block-item">
                            <h3 class="incepatori">Pentru incepători</h3>
                            <p class="incepatori1">Regulile de joc<br> Combinațiile elementare<br> Debutul, Jocul de mijloc,<br> finalurile simple</p>
                            <img src="@/assets/img/chess-piece.svg" alt="#" class="chess-piece">
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="block-item">
                            <h3 class="avansati">Pentru avansați</h3>
                            <p class="avansati1">Jocul strategic<br>Lupta pentru centru<br>Tipurile de atac și apărare<br>Avantajul pozițional<br></p>
                            <img src="@/assets/img/horse.svg" alt="#" class="horse">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-three">
            <div class="container">
                <div class="row">
                    <div class="section_title">
                        <img src="@/assets/img/horse1.svg" alt="#" class="horse1">
                        <p class=""><span>Platformă interactivă</span><br>în loc de manuale</p>
                    </div>
                    <p class="platform1">Chat cu profesorul, exerciții interactive,<br> pauze recreative și fapte interesante</p>
                </div>
                <div class="row">
                    <div class="photo-macbook">
                        <img src="@/assets/img/macbook.png" alt="#" class="macbook">
                    </div>
                </div>
            </div>
        </section>
        <section class="section-four" :style="`background: url(${require('@/assets/img/Rectangle33.png')}) no-repeat center / cover;`">
            <div class="container">
                <div class="row section_title">
                    <p class=""><span>Metodologie contemporană.</span><br>Transformăm învățarea într-un joc atractiv</p>
                </div>
                <div class="row section-items">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div class="item">
                            <h3 class="item_title" >Teorie adaptată</h3>
                            <p class="item_text">Regulile de joc<br> Combinațiile elementare<br> Debutul, Jocul de mijloc,<br> finalurile simple</p>
                            <img src="@/assets/img/to-do-list.svg" alt="" class="to-do">
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div class="item">
                            <h3 class="item_title" >Sarcini interesante</h3>
                            <p class="item_text">Istoria dezvoltării jocului<br> de șah, exerciții tactice în<br> format interactiv</p>
                            <img src="@/assets/img/plan.svg" alt="" class="to-do">
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div class="item">
                            <h3 class="item_title" >Pregătire către turnee</h3>
                            <p class="item_text">Deschidem tainele de luptă <br>și învățăm să câștigăm</p>
                            <img src="@/assets/img/knight.svg" alt="" class="to-do">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-five">
            <div class="container">
                <div class="row">
                    <h1 class="recenzii-title"><img src="@/assets/img/greetings.svg" alt="#" class="greetings">Ce zic cei care au încercat</h1>
                </div>
                <div class="row recenzii-block col-xl-12 col-lg-12 col-md-12 col-sm-12">

                    <div class="videos-container">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div class="recenzie_item">
                            <div class="image">
                                <video width="100%" height="229.5" controls>
                                    <source src="@/assets/videos/recenzie1.mp4" type="video/mp4">
                                </video>
                            </div>
                            <div class="label">Sofia</div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div class="recenzie_item">
                            <div class="image">
                                <video width="100%" height="229.5" controls>
                                    <source src="@/assets/videos/recenzie3.mp4" type="video/mp4">
                                </video>
                            </div>
                            <div class="label">Constantin</div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <div class="recenzie_item">
                            <div class="image">
                                <video width="100%" height="229.5" controls>
                                    <source src="@/assets/videos/recenzie2.mp4" type="video/mp4">
                                </video>
                            </div>
                            <div class="label">Părinții</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-six" :style="`background: url(${require('@/assets/img/Rectangle34.png')}) no-repeat center / cover;`">
            <div class="container">
                <div class="row">
                    <div class="name_section">
                        <p class="loc">Cum au loc cursurile</p>
                        <div>
                            <img src="@/assets/img/workaholic.svg" alt="#" class="workaholic">
                        </div>
                    </div>
                </div>
                <div class="row block-curs">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div class="item">
                            <p class="cifra">1</p>
                            <p class="lvl1">Începutul orei</p>
                            <p class="lvl3">Verificăm tema pe acasă,<br> discutăm tema lecției.</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div class="item">
                            <p class="cifra">2</p>
                            <p class="lvl1">Teorie</p>
                            <p class="lvl3">Studiem lecția nouă în dialog<br> cu profesorul</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div class="item">
                            <p class="cifra">3</p>
                            <p class="lvl1">Activitate practică</p>
                            <p class="lvl3">Întărim cunoștințele noi prin exerciții interactive sau jucăm șah</p>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                        <div class="item">
                            <p class="cifra">4</p>
                            <p class="lvl1">Feedback</p>
                            <p class="lvl3">Cu 10 minute înainte de încheierea lecției facem totalizarea.<br> Pedagogul fixează succesul și dă tema pentru acasă.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-seven">
            <div class="container">
                <div class="row">
                    <div class="name_section">
                        <p class="vezi_preturi">Vezi aici care sunt prețurile</p>
                        <div>
                            <img src="@/assets/img/price-tag.svg" alt="#" class="price-tag">
                        </div>
                    </div>
                </div>
                <div class="row prices">
                    <div class="price_block col-md-6 col-sm-6 col-6">
                        <div class="price_item price-eco">
                            <div class="econom">
                            <span><img src="@/assets/img/like.png" alt="#" class="like_1"></span>
                            <p class="c_econom">Cel mai econom</p>
                            </div>
                            <p class="t_64">64</p>
                            <p class="lectii">Lecții</p>
                            <p class="del">Pătrundem în strategie și participăm la turnee </p>
                            <!-- <p class="reducere">6.39 €/lectie<br/>Economie de 36%</p> -->
                        </div>
                    </div>
                    <div class="price_block col-md-6 col-sm-6 col-6">
                        <div class="price_item price-eco">
                            <div class="econom">
                            <span><img src="@/assets/img/like.png" alt="#" class="like_1"></span>
                            <p class="c_econom">Cel mai econom</p>
                            </div>
                            <p class="t_64">32</p>
                            <p class="lectii">Lecții</p>
                            <p class="del">Asimilăm procedeile<br> tacticei simple</p>
                            <!-- <p class="reducere">7.29 €/lectie<br/>Economie de 27%</p> -->
                        </div>
                    </div>
                    <div class="price_block col-md-6 col-sm-6 col-6">
                        <div class="price_item price-eco">
                            <div class="econom">
                            <span><img src="@/assets/img/like.png" alt="#" class="like_1"></span>
                            <p class="c_econom">Cel mai popular</p>
                            </div>
                            <p class="t_64">16</p>
                            <p class="lectii">Lecții</p>
                            <p class="del">Aflăm cum evităm greșelile tipice în deschideri </p>
                            <!-- <p class="reducere">8.19 €/lectie<br/>Economie de 18%</p> -->
                        </div>
                    </div>
                    <div class="price_block col-md-6 col-sm-6 col-6">
                        <div class="price_item">
                            <p class="t_64">8</p>
                            <p class="lectii">Lecții</p>
                            <p class="del">Studiem trăsăturile<br> pieselor de șah </p>
                            <!-- <p class="reducere">9.09 €/lectie<br/>Economie de 9%</p> -->
                        </div>
                    </div>
                    <div class="price_block col-md-6 col-sm-6 col-6">
                        <div class="price_item">
                            <p class="t_64">4</p>
                            <p class="lectii">Lecții</p>
                            <p class="del">Învățăm principiile de bază ale jocului de șah </p>
                            <!-- <p class="reducere">9.99 €/lectie</p> -->
                        </div>
                    </div>
                </div>
                <div class="name_section">
                        <p class="vezi_preturi" style="text-align:center;">Înregistrați-Vă la lecția de probă</p>
                    </div>
                <div class="registration_footer_form">
                    <div class="reg_form_txt">
                        <div class="rf-txt-img">
                            <img src="@/assets/img/job-interview.png" alt="#">
                            <p class="rf-txt">Facem cunoștință</p>
                        </div>
                        <div class="rf-txt-img">
                            <img src="@/assets/img/rank.png" alt="#">
                            <p class="rf-txt">Stabilim nivelul</p>
                        </div>
                        <div class="rf-txt-img">
                            <img src="@/assets/img/presentation.png" alt="#">
                            <p class="rf-txt">Prezentăm cum se<br>desfășoară lecția</p>
                        </div>
                    </div>
                    <div class="form-wrapper">
                        <RegistrationForm />
                    </div>
                </div>
            </div>
        </section>
        <Popup :show="display_error_box" @closeEvent="display_error_box=false">
            <h2 v-html="error_heading"></h2>
            <p v-html="error_text"></p>
        </Popup>
    </div>
</template>

<style lang="scss">
    section{
        &.main{
            padding-bottom: 100px;
            @media(max-width:768px){
                padding-bottom: 40px;
            }
            .copii{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .hero-wrapper{
                    position: relative;
                    margin: 120px 100px 60px;
                    .hero-block{
                        position: relative;
                        background: #fff;
                        border: 2px solid #CF0920;
                        display: inline-block;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        border-radius: 20px;
                        padding: 60px 30px 30px;
                        min-width: 425px;
                        .hero-caption{
                            font-size: 26px;
                            line-height: 32px;
                            color: #CF0920;
                            font-weight: bold;
                            margin-bottom: 20px;
                        }
                        >p{
                            font-size: 28px;
                            line-height: 35px;
                            color: #141414;
                            >ul{
                                list-style: disc;
                                margin-left: 25px;
                                li{
                                    list-style: inherit;
                                }
                            }
                        }
                    }

                    >.g{
                        position:absolute;
                        height: 0px;
                        left: 0px;
                        right: 0px;
                        z-index: 1;
                        &.g-top{
                            top:0px;
                        }
                        &.g-bottom{
                            bottom:0px;
                        }
                        .c1,.c2,.c3{
                            position: absolute;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                        >.c1{
                            bottom: -60px;
                            left: -100px;
                            height: 229px;
                            width: 116px;
                            background-image:url(/images/ch1.png);
                        }
                        >.c2{
                            bottom: -20px;
                            right: -97px;
                            background-image:url(/images/ch2.png);
                            width: 111px;
                            height: 222px;
                        }
                        >.c3{
                            top: -125px;
                            right: 10px;
                            background-image:url(/images/ch3.png);
                            width: 425px;
                            height: 177px;
                        }
                    }
                    >.cloud-wrapper{
                        position: absolute;
                        width: 145px;
                        left: -220px;
                        top: -140px;
                        z-index:2;
                        img{

                        }
                        .cloud-text{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;

                            font-size: 21px;
                            line-height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #0B1022;
                            transform: rotate(20deg);
                            padding: 20px 10px 40px;

                            text-align: center;
                        }
                    }
                }
                @media(max-width:1200px){
                    .cloud-wrapper{
                        display: none;
                    }
                }
            }
            @media(max-width:992px){

            }
        }
        &.section-three{
            .hourse1{
                position: absolute;
                width: 120px;
                left: 160px;
                top: 15px;
            }
            .photo-macbook {
                width: 100%;
                text-align: center;
                margin-top: 50px;
                padding-bottom: 130px
            }
            .back_circle3 {
                position: absolute;
                width: 294px;
                right: 10%;
                bottom: 7%;
            }
            @media(max-width:1024px){
                .hourse1{
                    left:65px;
                }
            }
            @media(max-width:768px){
                text-align: center;
                .hourse1{
                    position: relative;
                    left: 0;
                    width: 72px;
                }
                .back_circle3{
                    display:none;
                }
                .photo-macbook{
                    padding-bottom: 60px;
                }
            }
        }
        &.section-four{
            .section-items {
                display: grid;
                margin-top: 100px;
                grid-template-columns: repeat(3,1fr);
                grid-gap:30px;
                align-items: flex-start;
                .item {
                    background: #fff;
                    border: 1px solid #e0e0e0;
                    box-sizing: border-box;
                    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                    border-radius: 10px;
                    padding: 38px 55px 38px 38px;
                    position: relative;
                    height: 100%;
                    min-height: 370px;
                    width: auto;
                    &.item:nth-child(2) {
                        margin-top: -24px
                    }
                    &:last-child {
                        margin-right: 0
                    }
                    .item_title {
                        font-family: FuturaPT;
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 30px;
                        color: #444;
                        margin-bottom: 24px;
                    }
                    .item_text {
                        font-family: FuturaPT;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 23.9012px;
                        line-height: 31px;
                        color: #565656;
                        padding-bottom: 80px
                    }
                    @media(max-width:1200px){
                    .item_text{
                        font-size: 18px;
                        line-height: 23px;
                    }
                    }
                    .to-do {
                        position: absolute;
                        left: 30px;
                        bottom: 38px;
                    }
                }
                @media(max-width:1200px){
                    .item{
                        height: 445px;
                    }
                }

                @media(max-width:991px){
                    .item{
                        height: 400px;
                    }

                }
                @media(max-width:991px){
                    .item{
                        height: 370px;
                    }

                }
                @media(max-width:768px){
                    margin-top: 20px;
                    grid-template-columns: repeat(1,1fr);
                    &>div{
                        margin-bottom:24px;
                    }
                }
            }
        }
        &.section-five{
            .recenzii-title {
                font-family: FuturaPT;
                font-weight: bold;
                font-size: 48px;
                line-height: 62px;
                color: #0b1022;
                text-align: center;
                padding-top: 160px;
                margin-bottom: 50px;
            }
            .greetings{
                width: 92px;
                margin-right: 35px;
            }
            .videos-container{
                display:grid;
                grid-template-columns: repeat(3,1fr);
                grid-gap:30px;
            }
            @media(max-width:768px){
                .videos-container{
                    grid-template-columns: repeat(1,1fr);
                }
                .recenzii-title{
                    font-size: 35px;
                    padding-top: 60px;
                }
                .greetings{
                    display: block;
                    margin: 0 auto;
                    width: 80px;
                }
            }
        }
        &.section-six,&.section-seven{
           .name_section {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                margin-bottom: 60px;
                .loc{
                    font-family: FuturaPT;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 62px;
                    color: #0b1022
                }
                .vezi_preturi {
                    font-family: FuturaPT;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 61px;
                    color: #0b1022
                }
                &>div {
                    position: absolute;
                    right: 135px
                }
            }
            .price-tag {
                width: 72px;
                height: 72px;
                left: 164px;
                top: 49px
            }
            .workaholic {
                width: 120px;
                height: 120px;
                left: 931px;
                top: 0
            }
            .block-curs{
                display: grid;
                grid-template-columns: repeat(4,1fr);
                grid-gap:30px;
                .item{
                    height: 286px;
                    left: 0;
                    top: 177px;
                    background: #fff;
                    border: 1px solid #e0e0e0;
                    box-sizing: border-box;
                    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .cifra {
                        font-family: FuturaPT;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 69px;
                        line-height: 74px;
                        color: #444;
                        margin-top: 20px
                    }
                }
            }
            .prices{
                display: grid;
                grid-template-columns: repeat(5,1fr);
                grid-gap: 15px;
                .price_block{
                    width: 100%;
                    .price_item {
                        background: #fff;
                        position: relative;
                        border: 1px solid #e0e0e0;
                        border-radius: 10px;
                        padding-top: 72px;
                        padding-bottom: 56px;
                        box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                        margin-bottom: 64px;
                        &.price-eco{
                            border: 4px solid #eeae28;
                        }
                        .econom {
                            position: absolute;
                            width: 100%;
                            background: #eeae28;
                            color: #fff;
                            top: 0;
                            left: 0;
                            height: 40px;
                            span {
                                position: absolute;
                                left: 20px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            .like_1 {
                                left: 8.52%;
                                right: 84.66%;
                                top: 2%
                            }
                            .c_econom {
                                height: 100%;
                                font-family: FuturaPT;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 21px;
                                text-align: center;
                                font-size: 16px;
                                line-height: 40px;
                                letter-spacing: .5px;
                                color: #fff
                            }
                        }
                        .t_64 {
                            height: 64px;
                            font-family: FuturaPT;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 64px;
                            line-height: 64px;
                            text-align: center;
                            letter-spacing: 2px;
                            color: #0b1022;
                        }
                        .lectii {
                            font-family: FuturaPT;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 32px;
                            line-height: 32px;
                            letter-spacing: 1px;
                            text-align: center;
                            color: rgba(11, 16, 34, 0.3);
                        }
                        .del {
                            height: 40px;
                            font-family: FuturaPT;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 20px;
                            text-align: center;
                            letter-spacing: 1px;
                            color: #0b1022;
                        }

                        .reducere {
                            height: 20px;
                            font-family: FuturaPT;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 20px;
                            text-align: center;
                            letter-spacing: .5px;
                            color: #cf0920;
                        }
                    }
                }
            }
            .registration_footer_form{
                        margin: 0 auto;
                        width: 100%;
                        max-width: 928px;
                        border: 1px solid #F3F5F9;
                        box-sizing: border-box;
                        box-shadow: 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275);
                        border-radius: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 45px 0px 45px;
                .form-wrapper{
                    flex:0;
                }
            }
            @media(max-width:769px){
                .registration_footer_form{
                    flex-direction: column;
                    padding-top: 30px;
                }
            }

            @media(max-width:992px){
                .block-curs{
                    .item{
                        margin-bottom: 20px;
                    }
                }
                .prices{
                    grid-template-columns: repeat(2,1fr);
                    .price_block{
                        .price_item {
                        }
                    }
                }
                .group_img{
                    display: none;
                }
            }
            @media(max-width:768px){
                .name_section{
                    margin-bottom:35px;
                    .loc{
                        font-size:35px;
                    }
                    .vezi_preturi {
                        font-size: 35px;
                    }
                    .price-tag{
                        display: none;
                    }
                }
                .workaholic{
                    display:none;
                }
                .block-curs{
                    padding-bottom: 60px;
                    grid-template-columns: repeat(1,1fr);
                }
                .prices{
                    .price_block{
                        .price_item {
                            padding-top: 60px;
                            padding-bottom: 45px;
                            .t_64{
                                font-size: 55px;
                            }
                        }
                    }
                }
            }

            @media(max-width:480px){
                .prices{
                    .price_block{
                        .price_item {
                            .like_1{
                                display: none;
                            }
                            .del{
                                font-size: 14px;
                                margin-bottom: 0;
                                line-height: 14px;
                            }
                            .reducere{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }

        }
        .registration_form{
            margin-top: 24px;
        }


        .text_s {
            left: 24.83%;
            top: 128px;
            bottom: 59.82%;
            font-family: FuturaPT;
            font-style: normal;
            font-weight: 500;
            font-size: 23.9012px;
            line-height: 30px;
            text-align: center;
            color: #545454;
            @media(max-width:768px){
                display:none;
            }
        }
        .blocks {
            width: 100%;
            margin-bottom: 100px;
            display: grid !important;
            grid-template-columns: repeat(2,1fr);
            grid-gap:30px;
            .block-item {
                position: relative;
                padding: 35px;
                height: 100%;
                margin-right: 30px;
                background: #fff;
                border: 1px solid #e0e0e0;
                box-sizing: border-box;
                box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
                border-radius: 10px;
                &:last-child {
                    margin-right: 0
                }
            }
            @media(max-width:768px){
                margin-bottom: 30px;
                grid-template-columns: repeat(1,1fr);
                &>div{
                    margin-bottom:33px;
                }
            }
        }
    }
</style>

<script>
    import i18n from '@/i18n';
    import RegistrationForm from '@/views/components/RegistrationForm/RegistrationForm.vue';
    import Popup from '@/views/components/Popup/Popup.vue';
    import env from '@/enviroment';
    import {rest} from "@/utils/utils";

    export default {
        name: 'Homepage',
        components: {
            RegistrationForm,
            Popup
        },
        data: function () {
            return {
                display_error_box:false,
                error_heading:"",
                error_text:"",
                hero:{
                    cloud:"De la 5 ani",
                    caption:"Cadouri speciale la înscriere:",
                    description:`<ul>
                    <li>Plan personalizat pentru progres</li>
                    <li>Ghid „Magia jocului antic”</li>
                    <li>Concurs cu premii atractive</li>
                    <li>800 mii exerciții pe șah.md</li>
                    </ul>`
                }
            }
        },
        methods: {},
        computed:{
            url_prefix(){
                return (i18n.locale=="ro"?"":"/"+i18n.locale);
            }
        },
        mounted(){
            window.VerboxSetup = {
                language: "ru",
            };

            var params = new URLSearchParams(window.location.search);
            if(params && params.get('action')){
                switch(params.get('action')){
                    case 'login':
                        if(params.get('error_code')){
                            switch(params.get('error_code')){
                                case '411':
                                    this.display_error_box = true;
                                    this.error_heading = "Autentificare esuata";
                                    this.error_text = "Așa utilizator nu există. Rugăm să Vă înregistrați";
                                break;
                            }
                        }
                    break;
                }
            }

            /* rest.call("get_landing_hero_text",{}).then((response)=>{
                this.hero.cloud = response.cloud;
                this.hero.caption = response.caption;
                this.hero.description = response.description;
            }); */
        },
    }
</script>
